import React from 'react';

const PlayerSearchBarResultsList = ({ players, handlePlayerClick }) => {
	if (players.length === 0) return null;

	return (
		<div
			className="absolute bg-white border mt-2 p-2 rounded-md shadow-lg z-30 max-h-64 overflow-y-auto"
			style={{ top: '50px', left: '30px' }}
		>
			<ul>
				{players.map((player) => (
					<li
						key={player.id}
						className="p-2 cursor-pointer flex items-center"
						onClick={() => handlePlayerClick(player)}
					>
						<div className="hidden sm:block">
							<img
								className="relative mr-2"
								src={`${player.image_url}?w=50&h=50`}
								alt={`${player.first_name} ${player.last_name}`}
								style={{
									width: '50px',
									height: '50px',
									objectFit: 'cover',
									border: '1px solid #ddd',
									borderRadius: '50%',
									backgroundColor: 'white'
								}}
							/>
						</div>
						<span className="hover:underline text-xs md:text-base">
							{player.first_name} {player.last_name}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default PlayerSearchBarResultsList;
