import React, { useState, useEffect } from 'react';
import FilterByTeam from '../../Filters/FilterByTeam';
import FilterBySearchInput from '../../Filters/FilterBySearchInput';
import ClearFiltersButton from '../../Filters/ClearFilterButton/ClearFiltersButton';
import TeamsProfileStatsTable from '../TeamsProfileStatsTable/TeamsProfileStatsTable';

export default function TeamsIndexCard(props) {
	const [
		teams,
		setTeams
	] = useState([]);

	// Filter and sort state variables
	const [
		filter,
		setFilter
	] = useState({ team: '' });
	const [
		sortBy,
		setSortBy
	] = useState('division');
	const [
		sortOrder,
		setSortOrder
	] = useState('asc');
	const [
		search,
		setSearch
	] = useState('');

	useEffect(
		() => {
			fetchTeams();
		},
		[
			filter,
			sortBy,
			sortOrder,
			search
		]
	);

	const fetchTeams = () => {
		let url = `${props.get_teams_search_by_coach_endpoint}?team=${filter.team}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`;
		fetch(url)
			.then((response) => response.json())
			.then((result) => {
				setTeams(result.data || []);
			})
			.catch((error) => {
				console.error('Error fetching teams:', error);
			});
	};

	const handleFilterChange = (filterKey, value) => {
		setFilter((prevFilter) => ({ ...prevFilter, [filterKey]: value }));
	};

	const handleSearchInputChange = (event) => {
		setSearch(event.target.value);
	};

	const handleSortChange = (event) => {
		const { value } = event.target;
		setSortBy(value);
		// If sorting on a different column, reset default sort order to 'asc'
		if (value !== sortBy) {
			setSortOrder('asc');
		}
	};

	const handleSortOrderChange = (sortColumn, sortOrder) => {
		if (sortOrder === '' || sortOrder === 'desc') {
			setSortBy(sortColumn);
			setSortOrder('asc');
		} else if (sortOrder === 'asc') {
			setSortBy(sortColumn);
			setSortOrder('desc');
		}
	};

	const handleToggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};

	const handleClearFilters = () => {
		setFilter({ team: '' });
		setSortBy('division');
		setSortOrder('asc');
		setSearch('');
	};

	return (
		<div>
			{/* Filter components */}
			<div className="flex flex-col lg:flex-row lg:mb-6">
				<div className="md:mr-6">
					<FilterByTeam filter={filter} onChange={handleFilterChange} />
				</div>
				<div className="md:mr-6">
					<FilterBySearchInput
						label="Filter By Coach"
						value={search}
						placeholder="Search for a coach"
						onChange={handleSearchInputChange}
						onEnter={fetchTeams}
					/>
				</div>
				<div className="hidden lg:contents">
					<ClearFiltersButton onClick={handleClearFilters} />
				</div>
			</div>

			{/* Display teams */}
			<div className="p-6 lg:p-8 border border-gray-300 rounded-xl shadow-lg bg-white">
				<TeamsProfileStatsTable
					teams={teams}
					sortBy={sortBy}
					sortOrder={sortOrder}
					handleSortOrderChange={handleSortOrderChange}
				/>
			</div>
		</div>
	);
}
