import React, { useState } from 'react';

const UserAssessmentsTable = ({ userAssessments }) => {
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [assessmentDetails, setAssessmentDetails] = useState(null);

  const handleSeeDetailsClick = async (assessmentId) => {
    const response = await fetch(`/api/v1/user_assessments/${assessmentId}`);
    const data = await response.json();
    setAssessmentDetails(data);
    setSelectedAssessment(assessmentId);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setAssessmentDetails(null);
  };

  return (
    <div className="max-h-80 overflow-y-auto rounded-lg border border-gray-200 text-gray-800">
      <table className="min-w-full bg-white">
        <thead className="bg-gray-100 sticky top-0 z-2">
          <tr>
            <th className="py-3 px-12 border-b text-left">Score</th>
            <th className="py-3 px-12 border-b text-left">Date Completed</th>
            <th className="py-3 px-12 border-b text-left">Passed</th>
            <th className="py-3 px-12 border-b text-left">Results</th>
          </tr>
        </thead>
        <tbody>
          {userAssessments.map((assessment) => (
            <tr key={assessment.id} className="hover:bg-gray-50">
              <td className="py-2.5 px-12 border-b text-left">{assessment.score}%</td>
              <td className="py-2.5 px-12 border-b text-left">
                {new Date(assessment.completed_at).toLocaleDateString()}
              </td>
              <td className="py-2.5 px-12 border-b text-left">{assessment.passed ? 'Yes' : 'No'}</td>
              <td className="py-2.5 px-12 border-b text-left">
                <button className="text-blue-500 hover:underline" onClick={() => handleSeeDetailsClick(assessment.id)}>
                  See details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for assessment details */}
      {modalVisible && assessmentDetails && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-10">
          <div className="bg-white rounded-lg p-6 w-3/4 max-h-screen overflow-auto relative">
            <h2 className="text-xl font-bold mb-4">Assessment Details</h2>
            {assessmentDetails.questions.map((question) => {
              const userAnswerId = question.user_answer_id; // This is the user's selected answer
              const isCorrect = question.answers.some(
                (answer) => answer.answer_id === parseInt(userAnswerId) && answer.correct
              );

              return (
                <div key={question.question_id} className="mb-4">
                  <p className="font-medium">{question.question_text}</p>
                  <div className="ml-4">
                    {question.answers.map((answer) => (
                      <div key={answer.answer_id} className="flex items-center">
                        <input
                          type="radio"
                          disabled
                          checked={answer.answer_id === parseInt(userAnswerId)}
                          className="mr-2"
                        />
                        <label>{answer.answer_text}</label>
                      </div>
                    ))}
                  </div>
                  {/* Show correct/incorrect indicators */}
                  <div className="ml-2">
                    {isCorrect ? (
                      <span className="text-green-500">✓ Correct</span>
                    ) : (
                      <span className="text-red-500">✗ Incorrect</span>
                    )}
                  </div>

                  {/* Show explanation if incorrect */}
                  {!isCorrect && (
                    <div className="text-red-600 mt-2">
                      <p>Correct Answer: {question.correct_answer_text}</p>
                      <p>Explanation: {question.explanation}</p>
                    </div>
                  )}
                </div>
              );
            })}
            {/* Close button */}
            <button className="bg-red-500 text-white px-4 py-2 mt-4" onClick={closeModal}>
              Close
            </button>

            {/* "X" close icon */}
            <button
              className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200 transition duration-300"
              onClick={closeModal}
            >
              <svg
                className="h-6 w-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAssessmentsTable;
