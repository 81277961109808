import React from 'react';

const TeamSearchBarResultsList = ({ teams, handleTeamClick }) => {
	if (teams.length === 0) return null;

	return (
		<div
			className="absolute bg-white border mt-2 p-2 rounded-md shadow-lg z-30 max-h-64 overflow-y-auto"
			style={{ top: '50px', left: '30px' }}
		>
			<ul>
				{teams.map((team) => (
					<li
						key={team.id}
						className="p-2 cursor-pointer flex items-center"
						onClick={() => handleTeamClick(team)}
					>
						<div className="hidden sm:block">
							<img
								className="relative mr-2"
								src={`${team.image_url}?w=50&h=50`}
								alt={`${team.name}`}
								style={{
									width: '50px',
									height: '50px',
									objectFit: 'cover',
									border: '1px solid #ddd',
									borderRadius: '50%',
									backgroundColor: 'white'
								}}
							/>
						</div>
						<span className="hover:underline text-xs md:text-base">
							{team.name}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default TeamSearchBarResultsList;
