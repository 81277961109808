import React from 'react';
import FieldErrors from '../ErrorComponents/FieldErrors/FieldErrors';
import HelperText from './HelperText';

export default class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }));
  };

  render() {
    const {
      icon,
      id,
      name,
      style,
      placeholder,
      value,
      onChange,
      helperText,
      fieldErrors
    } = this.props;

    const { showPassword } = this.state;

    return (
      <div className="PasswordInput mt-1 mb-4">
        <div className="relative">
          {/* Password input field */}
          <input
            type={showPassword ? 'text' : 'password'}
            autoCapitalize="off"
            autoComplete="off"
            className={`block border border-gray-300 w-full p-2 px-3 h-10 rounded ${icon ? 'has-icon' : ''}`}
            id={id}
            name={name}
            style={style}
            placeholder={placeholder}
            value={value || ''}
            onChange={onChange}
          />

          {/* Eye icon for showing/hiding password */}
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center justify-center pr-3 h-full"
            onClick={this.toggleShowPassword}
          >
            <i
              className={`fa-solid ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}
              style={{ fontSize: '16px' }}
            />
          </button>
        </div>

        {/* Helper text and error handling */}
        <div className="p-1">
          {fieldErrors && fieldErrors.hasOwnProperty(name) ? (
            <FieldErrors fieldName={name} fieldErrors={fieldErrors} />
          ) : (
            <HelperText>{helperText}</HelperText>
          )}
        </div>
      </div>
    );
  }
}
