import React, { useState, useEffect } from 'react';

const VideoCarousel = (props) => {
	// console.log('VideoCarousel - Videos:', props.videos);

	const [
		currentVideoIndex,
		setCurrentVideoIndex
	] = useState(0);

	const [
		videoHeight,
		setVideoHeight
	] = useState(getVideoHeight());

	// Function to get the appropriate video height based on window width
	function getVideoHeight() {
		if (window.innerWidth < 640) {
			return 200; // Small screens
		} else if (window.innerWidth < 1024) {
			return 400; // iPad screens
		} else {
			return 650; // Larger screens
		}
	}

	// Function to update video height
	const updateDimensions = () => {
		setVideoHeight(getVideoHeight());
	};

	// Effect to handle window resize
	useEffect(() => {
		window.addEventListener('resize', updateDimensions);
		// Call the function initially to set the correct height
		updateDimensions();

		return () => {
			window.removeEventListener('resize', updateDimensions);
		};
	}, []);

	const prevVideo = (event) => {
		event.preventDefault();
		setCurrentVideoIndex(
			(prevIndex) => (prevIndex === 0 ? props.videos.length - 1 : prevIndex - 1)
		);
	};

	const nextVideo = (event) => {
		event.preventDefault();
		setCurrentVideoIndex(
			(prevIndex) => (prevIndex === props.videos.length - 1 ? 0 : prevIndex + 1)
		);
	};

	// Handle browser back button
	useEffect(() => {
		const handlePopstate = (event) => {
			event.preventDefault();
			window.history.forward();
		};

		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		};
	}, []);

	if (!props.videos || props.videos.length === 0) {
		return <div>No videos available</div>;
	}

	return (
		<div>
			<div className="border rounded-xl bg-white">
				<div className="relative flex items-center justify-center">
					<button
						onClick={prevVideo}
						className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
						style={{ left: '-15px' }}
					>
						{'<'}
					</button>
					<div
						style={{
							position: 'relative',
							width: '100%',
							height: `${videoHeight}px`
						}}
					>
						<iframe
							className="rounded-xl"
							style={{
								position: 'absolute',
								width: '100%',
								height: `${videoHeight}px`,
								top: 0,
								left: 0
							}}
							src={props.videos[currentVideoIndex]}
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowFullScreen
						/>
					</div>
					<button
						onClick={nextVideo}
						className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
						style={{ right: '-15px' }}
					>
						{'>'}
					</button>
				</div>
			</div>
		</div>
	);
};

export default VideoCarousel;
