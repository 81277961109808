import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

const PasswordResetUpdate = () => {
	const [
		password,
		setPassword
	] = useState('');
	const [
		confirmPassword,
		setConfirmPassword
	] = useState('');
	const [
		showPassword,
		setShowPassword
	] = useState(false); // Toggle for password visibility
	const [
		showConfirmPassword,
		setShowConfirmPassword
	] = useState(false); // Toggle for confirm password visibility
	const [
		error,
		setError
	] = useState(null);
	const [
		successMessage,
		setSuccessMessage
	] = useState(null); // New state for success message
	const [
		csrfToken,
		setCsrfToken
	] = useState('');

	const search = useLocation().search;
	const token = new URLSearchParams(search).get('reset_password_token');

	useEffect(() => {
		const fetchCsrfToken = async () => {
			try {
				const response = await fetch('/csrf-token', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				});

				if (response.ok) {
					const data = await response.json();
					setCsrfToken(data.csrfToken);
				} else {
					console.error('Failed to fetch CSRF token');
				}
			} catch (error) {
				console.error('Error fetching CSRF token:', error);
			}
		};

		fetchCsrfToken();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setError('Passwords do not match');
			return;
		}

		try {
			const response = await fetch('/users/password', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
				},
				body: JSON.stringify({
					user: {
						password,
						password_confirmation: confirmPassword,
						reset_password_token: token
					}
				})
			});

			if (response.ok) {
				setSuccessMessage('Your password has been reset.');
				setError(null);
			} else {
				const data = await response.json();
				setError(
					data.error ||
						'Invalid request. Please start over as your reset link has expired or already been used.'
				);
			}
		} catch (error) {
			console.error('Error resetting password:', error);
			setError('An error occurred while resetting your password.');
		}
	};

	return (
		<div className="flex flex-col items-center justify-start min-h-screen bg-gray-50 pt-12">
			<div className="w-full max-w-md p-8 space-y-6 bg-white shadow-xl rounded-lg border">
				<h2 className="text-3xl font-extrabold text-gray-900">
					Update your password
				</h2>
				{successMessage ? (
					<p className="mt-4 text-left text-sm font-semibold text-green-600">
						{successMessage}{' '}
						<a href="/signin" className="text-blue-600 underline">
							Click here to login
						</a>
					</p>
				) : (
					<Fragment>
						<p className="mt-2 text-sm text-gray-600">
							Enter your new password to complete the reset process.
						</p>
						{error && (
							<p className="mt-4 text-center text-sm font-semibold text-red-600">
								{error}
							</p>
						)}
						<form onSubmit={handleSubmit} className="space-y-6">
							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700"
								>
									New Password
								</label>
								<div className="relative">
									<input
										type={showPassword ? 'text' : 'password'}
										id="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										placeholder="New Password"
										required
										className="w-full px-4 py-3 mt-1 text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pr-10 relative"
									/>
									<button
										type="button"
										className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
										onClick={() => setShowPassword((prev) => !prev)}
									>
										<i
											className={`fa-solid ${showPassword
												? 'fa-eye-slash'
												: 'fa-eye'}`}
										/>
									</button>
								</div>
							</div>

							<div>
								<label
									htmlFor="confirmPassword"
									className="block text-sm font-medium text-gray-700"
								>
									Confirm Password
								</label>
								<div className="relative">
									<input
										type={showConfirmPassword ? 'text' : 'password'}
										id="confirmPassword"
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										placeholder="Confirm Password"
										required
										className="w-full px-4 py-3 mt-1 text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pr-10 relative"
									/>
									<button
										type="button"
										className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
										onClick={() => setShowConfirmPassword((prev) => !prev)}
									>
										<i
											className={`fa-solid ${showConfirmPassword
												? 'fa-eye-slash'
												: 'fa-eye'}`}
										/>
									</button>
								</div>
							</div>

							<button
								type="submit"
								className="w-full px-6 py-3 text-lg font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								Reset Password
							</button>
						</form>
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default PasswordResetUpdate;
