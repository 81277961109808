import React from 'react';
import { Fragment } from 'react';

const CompareTeamGeneralInfo = ({ selectedTeams }) => {
	return (
		<Fragment>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Team</td>
				{selectedTeams.map((team, index) => (
					<td key={`name-${index}`} className="px-6 py-4">
						<div className="flex items-center">
							<div className="relative mr-4">
								<img
									src={`${team.image_url}?w=75&h=75`}
									alt={`${team.name}`}
									style={{
										width: '75px',
										height: '75px',
										objectFit: 'cover',
										border: '1px solid #ddd',
										borderRadius: '50%',
										backgroundColor: 'white'
									}}
								/>
							</div>
							<div>{team.name}</div>
						</div>
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Location</td>
				{selectedTeams.map((team, index) => (
					<td key={`location-${index}`} className="px-6 py-4">
						{team.location}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Established</td>
				{selectedTeams.map((team, index) => (
					<td key={`established-${index}`} className="px-6 py-4">
						{team.year_established}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Conference</td>
				{selectedTeams.map((team, index) => (
					<td key={`conference-${index}`} className="px-6 py-4">
						{team.conference}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Division</td>
				{selectedTeams.map((team, index) => (
					<td key={`conference-${index}`} className="px-6 py-4">
						{team.division}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">
					Championships
				</td>
				{selectedTeams.map((team, index) => (
					<td key={`championships-${index}`} className="px-6 py-4">
						{team.championships}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Website</td>
				{selectedTeams.map((team, index) => (
					<td key={`website-${index}`} className="px-6 py-4">
						{team.website && (
							<a
								href={`https://${team.website.replace(/^(https?:\/\/)?/, '')}`}
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-600 hover:underline"
							>
								{team.website}
							</a>
						)}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Social</td>
				{selectedTeams.map((team, index) => (
					<td key={`social-${index}`} className="px-6 py-4">
						{(team.twitter || team.instagram || team.facebook) && (
							<div className="flex space-x-2">
								{team.twitter && (
									<a
										href={team.twitter}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600 hover:underline"
									>
										<i className="fab fa-twitter ml-2 text-gray-500" />
									</a>
								)}
								{team.instagram && (
									<a
										href={team.instagram}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600 hover:underline"
									>
										<i className="fab fa-instagram ml-2 text-gray-500" />
									</a>
								)}
							</div>
						)}
					</td>
				))}
			</tr>
		</Fragment>
	);
};

export default CompareTeamGeneralInfo;
