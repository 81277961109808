import React from 'react';
import PageTitle from '../../FormElements/PageTitle';
import TeamsIndexCard from '../../Teams/TeamsIndexCard/TeamsIndexCard';

export default function TeamsIndexPage({ get_teams_search_by_coach_endpoint }) {
	// Handle loading and error states
	if (!get_teams_search_by_coach_endpoint) {
		return (
			<div>Error: Missing endpoints required for TeamsIndexPage component</div>
		);
	}

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<PageTitle title="Teams" />
				<div className="grid grid-cols-1">
					<TeamsIndexCard get_teams_search_by_coach_endpoint={get_teams_search_by_coach_endpoint} />
				</div>
			</div>
		</div>
	);
}
