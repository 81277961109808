import React, { useState, useEffect, Fragment, useRef } from 'react';
import TeamSearchBar from '../../SearchBars/TeamSearchBar/TeamSearchBar';
import CompareTeamGeneralInfo from '../TeamComparisonModal/CompareTeamGeneralInfo/CompareTeamGeneralInfo';
import CompareTeamEnvironmentScores from '../TeamComparisonModal/CompareTeamEnvironmentScores/CompareTeamEnvironmentScores';

const TeamComparisonModal = ({
	get_teams_endpoint,
	isTeamCompareModalOpen,
	handleTeamComparModalClose,
	selectedTeams,
	handleTeamSelect,
	handleTeamRemove
}) => {
	const [
		showComparison,
		setShowComparison
	] = useState(false);
	const modalRef = useRef(null);

	const handleClickOutsideTeamComparisonModal = (event) => {
		if (!modalRef.current.contains(event.target)) {
			isTeamCompareModalOpen && handleTeamComparModalClose();
		}
	};

	useEffect(
		() => {
			if (isTeamCompareModalOpen) {
				document.addEventListener(
					'mousedown',
					handleClickOutsideTeamComparisonModal
				);
			} else {
				document.removeEventListener(
					'mousedown',
					handleClickOutsideTeamComparisonModal
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					handleClickOutsideTeamComparisonModal
				);
			};
		},
		[
			isTeamCompareModalOpen
		]
	);

	useEffect(
		() => {
			if (!isTeamCompareModalOpen) {
				setShowComparison(false);
			}
		},
		[
			isTeamCompareModalOpen
		]
	);

	const handleCompareClick = () => {
		setShowComparison(true);
	};

	const comparisonTable = (
		<div className="mt-4 overflow-y-auto">
			<div className="overflow-x-auto">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase bg-gray-100 border-t border-b border-gray-300"
							>
								Category
							</th>
							{selectedTeams.map((team, index) => (
								<th
									key={`team-${index}`}
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase bg-gray-100 border-t border-b border-gray-300"
								>
									{`Team ${index + 1}`}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						<CompareTeamGeneralInfo selectedTeams={selectedTeams} />
						<tr>
							<td colSpan={selectedTeams.length + 1}>
								<div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase bg-gray-100 border-t border-b border-gray-300">
									Team Environment Scores
								</div>
							</td>
						</tr>
						<CompareTeamEnvironmentScores selectedTeams={selectedTeams} />
					</tbody>
				</table>
			</div>
		</div>
	);

	return (
		<div
			className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 ${isTeamCompareModalOpen
				? 'block'
				: 'hidden'}`}
			style={{
				zIndex: 1000,
				overflowY: 'auto'
			}}
		>
			<div
				className="p-4 relative m-auto bg-white rounded-lg shadow-lg overflow-auto"
				style={{
					zIndex: 1001,
					width: '75%'
				}}
				ref={modalRef}
			>
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-2xl font-bold">Compare Teams</h2>
					<button
						className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200 transition duration-300"
						onClick={handleTeamComparModalClose}
					>
						<svg
							className="h-6 w-6 text-gray-600"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				{!showComparison ? (
					<div>
						{selectedTeams.length < 6 && (
							<TeamSearchBar
								className="border border-gray-300 rounded-lg p-2 w-full test-sm text-gray-800"
								get_teams_endpoint={get_teams_endpoint}
								handleTeamSelect={handleTeamSelect}
								isVisibleByDefault={true}
								isCompareModal={true}
								isDarkMode={true}
							/>
						)}
						<div className="mt-4 flex flex-col">
							{selectedTeams.length > 5 && (
								<div className="text-red-500 my-2 bg-red-50 px-4 py-3 rounded">
									You can compare up to five teams at once. Select five teams or
									less and try again.
								</div>
							)}
							<h3 className="text-lg font-bold mb-2">
								Selected Teams to Compare
							</h3>
							<div>
								{selectedTeams.map((team) => (
									<div
										key={team.id + '-team'}
										className="flex flex-col lg:flex-row justify-between items-center border-gray-200 border py-4 rounded-xl my-6 shadow-sm bg-white"
									>
										<div className="flex items-center w-96 ml-10 flex-grow">
											<div className="flex items-center">
												<div className="relative mr-4">
													<img
														src={`${team.image_url}?w=75&h=75`}
														alt={`${team.name}`}
														style={{
															width: '75px',
															height: '75px',
															objectFit: 'cover',
															border: '1px solid #ddd',
															borderRadius: '50%',
															backgroundColor: 'white'
														}}
													/>
												</div>
												<div>
													<div className="text-2xl font-bold hover:underline cursor-pointer">
														{team.name}
													</div>
												</div>
											</div>
										</div>
										<div className="lg:mr-10">
											<button
												className="text-sm text-gray-500 hover:text-gray-800"
												onClick={() => handleTeamRemove(team.id)}
											>
												Remove
											</button>
										</div>
									</div>
								))}
							</div>
							<div className="mt-2 self-end">
								<button
									className={`px-4 py-2 rounded ${selectedTeams.length < 2 ||
									selectedTeams.length > 5
										? 'bg-gray-300 text-gray-500 cursor-not-allowed'
										: 'bg-blue-500 text-white'}`}
									onClick={handleCompareClick}
									disabled={
										selectedTeams.length < 2 || selectedTeams.length > 5
									}
								>
									Compare
								</button>
							</div>
						</div>
					</div>
				) : (
					comparisonTable
				)}
			</div>
		</div>
	);
};

export default TeamComparisonModal;
