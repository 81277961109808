import React from 'react';
import PropTypes from 'prop-types';
import PasswordResetRequest from '../../PasswordResetRequest/PasswordResetRequest';

export default function PasswordResetRequestPage({
	user_password_create_endpoint
}) {
	if (!user_password_create_endpoint) {
		return <div>Error: Missing password reset endpoint</div>;
	}

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<PasswordResetRequest
				user_password_create_endpoint={user_password_create_endpoint}
			/>
		</div>
	);
}

PasswordResetRequestPage.propTypes = {
	user_password_create_endpoint: PropTypes.string.isRequired
};
