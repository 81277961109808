import React from 'react';
import PageTitle from '../../FormElements/PageTitle';
import ProspectsIndexCard from '../../Prospects/ProspectsIndexCard/ProspectsIndexCard';

export default function ProspectsIndexPage({
	get_players_endpoint,
	get_prospects_endpoint
}) {
	// Handle loading and error states
	if (!get_players_endpoint) {
		return (
			<div>
				Error: Missing endpoints required for ProspectsIndexPage component
			</div>
		);
	}

	if (!get_prospects_endpoint) {
		return (
			<div>
				Error: Missing endpoints required for ProspectsIndexPage component
			</div>
		);
	}

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<PageTitle title="College Prospects" />
				<div className="grid grid-cols-1">
					<ProspectsIndexCard
						get_prospects_endpoint={get_prospects_endpoint}
						get_players_endpoint={get_players_endpoint}
					/>
				</div>
			</div>
		</div>
	);
}
