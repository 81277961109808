import React from 'react';
import CourseProfileCard from '../../Course/CourseProfileCard/CourseProfileCard';

export default function CourseProfilePage({get_course_endpoint}) {
  return (
    <div>
      <div>
        <CourseProfileCard get_course_endpoint={get_course_endpoint} />
      </div>
    </div>
  );
}