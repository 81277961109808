import React, { useState } from 'react';

const RosterNotesCard = ({
	user,
	roster,
	update_notes_by_roster_endpoint,
	isDraftModeEnabled
}) => {
	const [
		notes,
		setNotes
	] = useState(roster.notes || '');

	const handleNotesChange = async (e) => {
		const newNotes = e.target.value;
		setNotes(newNotes);

		const endpoint = update_notes_by_roster_endpoint
			.replace('user_id', user.id)
			.replace('roster_id', roster.id);

		try {
			const response = await fetch(endpoint, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ notes: newNotes })
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			// Optionally handle response
			// const data = await response.json();
			// console.log('Notes updated successfully:', data);
		} catch (error) {
			console.error('Failed to update notes:', error);
		}
	};

	return (
		<div>
			<div className={`flex-1 bg-gray-100 rounded-lg p-4 shadow-md flex flex-col w-full lg:w-auto ${isDraftModeEnabled ? 'h-24 md:h-48' : 'h-36 md:h-60 '}`}> 
				<h3 className="font-semibold text-lg mb-2 text-gray-700 uppercase border-b border-gray-300 pb-2">
					NOTES
				</h3>
				<textarea
					value={notes}
					onChange={handleNotesChange}
					className="text-gray-700 flex-1 text-sm p-2 rounded-lg w-full h-full bg-gray-100 overflow-y-auto"
					placeholder="Click to add notes, to dos, etc."
				/>
			</div>
		</div>
	);
};

export default RosterNotesCard;
