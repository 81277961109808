import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const PasswordResetRequest = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [csrfToken, setCsrfToken] = useState('');
	const [loading, setLoading] = useState(false);

	// Fetch the CSRF token when the component mounts
	useEffect(() => {
		const fetchCsrfToken = async () => {
			try {
				const response = await fetch('/csrf-token', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				});

				if (response.ok) {
					const data = await response.json();
					setCsrfToken(data.csrfToken);
				} else {
					console.error('Failed to fetch CSRF token');
				}
			} catch (error) {
				console.error('Error fetching CSRF token:', error);
			}
		};

		fetchCsrfToken();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setMessage('');
		setLoading(true);

		try {
			const response = await fetch('/users/password', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
				},
				body: JSON.stringify({ user: { email } })
			});

			if (response.ok) {
				setMessage('Password reset instructions have been sent to your email.');
			} else {
				setMessage('Error sending reset instructions. Please try again.');
			}
		} catch (error) {
			console.error('Error sending reset request:', error);
			setMessage('Error sending reset instructions.');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex flex-col items-center justify-start min-h-screen bg-gray-50 pt-12">
			<div className="w-full max-w-md p-8 space-y-6 bg-white shadow-xl rounded-lg border">
				<h2 className="text-3xl font-extrabold text-gray-900">
					Reset your password
				</h2>
				<p className="mt-2 text-sm text-gray-600">
					Enter your email to receive password reset instructions
				</p>
				<form onSubmit={handleSubmit} className="space-y-6">
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium text-gray-700"
						>
							Email Address
						</label>
						<input
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="you@example.com"
							required
							className="w-full px-4 py-3 mt-1 text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
						/>
					</div>

					{/* Conditionally render spinner or button based on loading state */}
					{loading ? (
						<div className="flex justify-center">
							<LoadingSpinner />
						</div>
					) : (
						<button
							type="submit"
							className="w-full px-6 py-3 text-lg font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						>
							Send Password Reset
						</button>
					)}
				</form>
				{message && (
					<p className="mt-4 text-center text-sm font-semibold text-green-600">
						{message}
					</p>
				)}
			</div>
		</div>
	);
};

export default PasswordResetRequest;
