function getDifficultyLevel(level) {
    switch (level) {
        case 0:
            return 'Beginner';
        case 1:
            return 'Intermediate';
        case 2:
            return 'Advanced';
        default:
            return 'Unknown';
    }
}

export default getDifficultyLevel;