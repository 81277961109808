import React from 'react';
import { Link } from 'react-router-dom';
import AdvancedDataTable from '../../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../../DataTables/MobileDataTableCard';
import StatusWidget from '../../../StatusWidget/StatusWidget';
import convertInchesToFeetAndInches from '../../../../utils/convertInchesToFeetAndInches';

const PlayersProfileStatsTable = ({
	players,
	roster,
	sortBy,
	sortOrder,
	handleSortOrderChange,
	isDraftModeEnabled,
	selectedPlayers,
	handlePlayerSelect,
	handlePlayerHideFromList,
	handlePlayerModalOpen,
	handleTeamModalOpen
}) => {
	return (
		<div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={players}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left'
							},
							{
								/*{
								displayName: 'Team',
								apiName: 'team',
								alignment: 'left'
							},
							{
								displayName: 'Age (years)',
								apiName: 'age',
								alignment: 'left'
							},
							{
								displayName: 'Height (in.)',
								apiName: 'height',
								alignment: 'left'
							},
							{
								displayName: 'Weight (lbs.)',
								apiName: 'weight',
								alignment: 'left'
							},
							{
								displayName: 'Forty Time (sec)',
								apiName: 'forty_time',
								alignment: 'left'
							},
							{
								displayName: 'Fantasy Fitness Team Score™',
								apiName: 'team_grade',
								alignment: 'left'
							},
							{
								displayName: 'Fantasy Fitness Talent Score™',
								apiName: 'talent_grade',
								alignment: 'left'
							},
							{
								displayName: 'Fantasy Fitness Score™',
								apiName: 'fantasy_fitness_grade',
								alignment: 'left'
							} */
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div>
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:underline"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</div>
								{/* <div className="mt-4 font-semibold">
									{labels[1].displayName}
								</div>
								<div>
									<Link
										to={`/teams/${row.team_id}`}
										className="text-blue-500 hover:underline"
									>
										{row.team.name}
									</Link>
								</div>
								<div className="mt-4 font-semibold">
									{labels[2].displayName}
								</div>
								<div>{row.age}</div>
								<div className="mt-4 font-semibold">
									{labels[3].displayName}
								</div>
								<div>{convertInchesToFeetAndInches(row.height)}</div>
								<div className="mt-4 font-semibold">
									{labels[4].displayName}
								</div>
								<div>{row.weight}</div>
								<div className="mt-4 font-semibold">
									{labels[5].displayName}
								</div>
								<div>{row.forty_time}</div>
								<div className="mt-4 font-semibold">
									{labels[6].displayName}
								</div>
								<div>{row.team_grade}</div>
								<div className="mt-4 font-semibold">
									{labels[7].displayName}
								</div>
								<div>{row.talent_grade}</div>
								<div className="mt-4 font-semibold">
									{labels[8].displayName}
								</div>
								<div>{row.fantasy_fitness_grade}</div> */}
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={players}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					sortingEnabled={true}
					sortOrder={sortOrder}
					sortColumn={sortBy ? sortBy : ''}
					onChangeSortOrder={handleSortOrderChange}
					columns={[
						{
							displayName: 'Player',
							apiName: 'player',
							alignment: 'left'
						},

						// Show 'Age', 'Height', and 'Weight' columns only if Draft mode is disabled
						...(!isDraftModeEnabled
							? [
									{
										displayName: 'Team',
										apiName: 'team',
										alignment: 'left'
									},
									{
										displayName: 'Age (years)',
										apiName: 'age',
										alignment: 'left'
									},
									{
										displayName: 'Height (in.)',
										apiName: 'height',
										alignment: 'left'
									},
									{
										displayName: 'Weight (lbs.)',
										apiName: 'weight',
										alignment: 'left'
									},
									{
										displayName: 'Forty Time (sec)',
										apiName: 'forty_time',
										alignment: 'left'
									}
								]
							: []),

						{
							displayName: 'Fantasy Fitness Team Score™',
							apiName: 'team_grade',
							alignment: 'left'
						},
						{
							displayName: 'Fantasy Fitness Talent Score™',
							apiName: 'talent_grade',
							alignment: 'left'
						},
						{
							displayName: 'Fantasy Fitness Score™',
							apiName: 'fantasy_fitness_grade',
							alignment: 'left'
						},

						// Add 'Underdog ADP' column with condition
						...(isDraftModeEnabled && roster.platform === 'Underdog'
							? [
									{
										displayName: 'Underdog ADP',
										apiName: 'underdog_adp',
										alignment: 'left'
									}
								]
							: []),

						// Add 'FFPC ADP' column with condition
						...(isDraftModeEnabled && roster.platform === 'FFPC'
							? [
									{
										displayName: 'FFPC ADP',
										apiName: 'ffpc_adp',
										alignment: 'left'
									}
								]
							: []),

						// Add 'Yahoo! ADP' column with condition
						...(isDraftModeEnabled && roster.platform === 'Yahoo'
							? [
									{
										displayName: 'Yahoo! ADP',
										apiName: 'yahoo_adp',
										alignment: 'left'
									}
								]
							: []),

						// Add 'ESPN ADP' column with condition
						...(isDraftModeEnabled && roster.platform === 'ESPN'
							? [
									{
										displayName: 'ESPN ADP',
										apiName: 'espn_adp',
										alignment: 'left'
									}
								]
							: []),

						// Add 'NFL.com column with condition
						...(isDraftModeEnabled && roster.platform === 'NFL'
							? [
									{
										displayName: 'NFL ADP',
										apiName: 'nfl_adp',
										alignment: 'left'
									}
								]
							: []),

						// Add 'Draft Actions' column with condition
						...(isDraftModeEnabled
							? [
									{
										displayName: 'Draft Actions',
										apiName: 'draft',
										alignment: 'center'
									}
								]
							: [])
					]}
					rowRenderer={(row) => {
						const isDrafted = selectedPlayers.some(
							(player) =>
								player.id === row.id && player.position === row.position
						);

						// Hide row if player is drafted
						if (isDrafted) {
							return null;
						}

						return (
							<tr
								className={`pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60 ${isDraftModeEnabled
									? 'border-b border-gray-300'
									: 'border-b border-gray-200'}`}
								key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - AdvancedDataTable`}
							>
								{/* Rendering Player's name and link */}
								<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60">
									{isDraftModeEnabled ? (
										// If Draft mode is enabled, render player profile modal
										<div>
											<div
												onClick={() => handlePlayerModalOpen(row)}
												className="text-blue-500 hover:underline cursor-pointer"
											>
												<div
													className={`${isDrafted ? 'line-through' : ''}`}
													style={{ display: 'flex', alignItems: 'center' }}
												>
													<div
														style={{
															width: '50px',
															height: '50px',
															overflow: 'hidden',
															marginRight: '8px'
														}}
													>
														<img
															src={row.image_url + '?w=50&h=50'}
															alt={`${row.first_name} ${row.last_name}`}
															style={{
																width: '100%',
																height: '100%',
																objectFit: 'cover',
																border: '1px solid #ddd',
																borderRadius: '50%'
															}}
														/>
														<div
															style={{
																position: 'absolute',
																top: '15px',
																left: '70px'
															}}
														>
															<StatusWidget
																status={row.status}
																description={row.injury_description}
																hideLabel={true}
																is50pXThumbnail={true}
															/>
														</div>
													</div>
													{`${row.first_name} ${row.last_name}`}
												</div>
											</div>
										</div>
									) : (
										// If Draft mode is disabled, redirect to player's profile page
										<Link
											to={`/players/${row.id}?position=${row.position}`}
											className="text-blue-500 hover:underline"
											target={isDraftModeEnabled ? '_blank' : '_self'}
											rel="noopener noreferrer"
										>
											<div
												className={`${isDrafted ? 'line-through' : ''}`}
												style={{ display: 'flex', alignItems: 'center' }}
											>
												<div
													style={{
														width: '50px',
														height: '50px',
														overflow: 'hidden',
														marginRight: '8px'
													}}
												>
													<img
														src={row.image_url + '?w=50&h=50'}
														alt={`${row.first_name} ${row.last_name}`}
														style={{
															width: '100%',
															height: '100%',
															objectFit: 'cover',
															border: '1px solid #ddd',
															borderRadius: '50%'
														}}
													/>
													<div
														style={{
															position: 'absolute',
															top: '15px',
															left: '70px'
														}}
													>
														<StatusWidget
															status={row.status}
															description={row.injury_description}
															hideLabel={true}
															is50pXThumbnail={true}
														/>
													</div>
												</div>
												{`${row.first_name} ${row.last_name}`}
											</div>
										</Link>
									)}
								</td>

								{/* Rendering other columns */}
								{!isDraftModeEnabled && (
									<td className="pl-8 py-3 text-left">
										<Link
											to={`/teams/${row.team.id}`}
											className="text-blue-500 hover:underline cursor-pointer"
										>
											{row.team.name}
										</Link>
									</td>
								)}

								{/* Rendering Height status column */}
								{!isDraftModeEnabled && (
									<td className="pl-8 py-3 text-left">{row.age}</td>
								)}

								{/* Rendering Height status column */}
								{!isDraftModeEnabled && (
									<td className="pl-8 py-3 text-left">
										{convertInchesToFeetAndInches(row.height)}
									</td>
								)}

								{/* Rendering Weight status column */}
								{!isDraftModeEnabled && (
									<td className="pl-8 py-3 text-left">{row.weight}</td>
								)}

								{/* Rendering Forty Time status column */}
								{!isDraftModeEnabled && (
									<td className="pl-8 py-3 text-left">{row.forty_time}</td>
								)}

								<td className="pl-8 py-3 text-left">{row.team_grade}</td>
								<td className="pl-8 py-3 text-left">{row.talent_grade}</td>
								<td className="pl-8 py-3 text-left">
									{row.fantasy_fitness_grade}
								</td>

								{/* Rendering Underdog ADP column */}
								{isDraftModeEnabled &&
								roster.platform === 'Underdog' && (
									<td className="pl-8 py-3 text-left">
										{row.underdog_adp ? row.underdog_adp : '-'}
									</td>
								)}

								{/* Rendering FFPC ADP column */}
								{isDraftModeEnabled &&
								roster.platform === 'FFPC' && (
									<td className="pl-8 py-3 text-left">
										{row.ffpc_adp ? row.ffpc_adp : '-'}
									</td>
								)}

								{/* Rendering Yahoo ADP column */}
								{isDraftModeEnabled &&
								roster.platform === 'Yahoo' && (
									<td className="pl-8 py-3 text-left">
										{row.yahoo_adp ? row.yahoo_adp : '-'}
									</td>
								)}

								{/* Rendering ESPN ADP column */}
								{isDraftModeEnabled &&
								roster.platform === 'ESPN' && (
									<td className="pl-8 py-3 text-left">
										{row.espn_adp ? row.espn_adp : '-'}
									</td>
								)}

								{/* Rendering NFL ADP column */}
								{isDraftModeEnabled &&
								roster.platform === 'NFL' && (
									<td className="pl-8 py-3 text-left">
										{row.nfl_adp ? row.nfl_adp : '-'}
									</td>
								)}

								{/* Rendering Draft buttons column */}
								{isDraftModeEnabled && (
									<td>
										<button
											className={`my-0.5 px-3 py-1.5 w-full text-center cursor-pointer border border-b-1 rounded-xl text-xs ${isDrafted
												? ''
												: 'bg-green-500 text-white hover:bg-green-600'}`}
											onClick={() =>
												isDrafted
													? handlePlayerHideFromList(row.id, row.position)
													: handlePlayerSelect(row, true)}
										>
											Drafted by me
										</button>

										<button
											className={`my-0.5 px-3 py-1.5 w-full text-center cursor-pointer border border-b-1 rounded-xl text-xs ${isDrafted
												? ''
												: 'bg-gray-300 text-gray-800 hover:bg-gray-400'}`}
											onClick={() =>
												isDrafted
													? handlePlayerHideFromList(row.id, row.position)
													: handlePlayerSelect(row, false)}
										>
											Drafted by the field
										</button>
									</td>
								)}
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default PlayersProfileStatsTable;
