import React from 'react';
import PageTitle from '../../FormElements/PageTitle';
import CoursesIndexCard from '../../Courses/CoursesIndexCard/CoursesIndexCard';

export default function CoursesIndexPage({
	get_course_endpoint,
	get_courses_endpoint
}) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<PageTitle title="Courses" />
				<div className="grid grid-cols-1">
					<CoursesIndexCard get_courses_endpoint={get_courses_endpoint} />
				</div>
			</div>
		</div>
	);
}
