import React from 'react';
import ProspectProfileCard from '../../Prospect/ProspectProfileCard/ProspectProfileCard';

const handlePlayersClick = () => {
	window.location.href = `/prospects`;
};

export default function ProspectProfilePage({
  get_prospect_endpoint,
  get_prospects_endpoint,
	get_player_endpoint,
	get_players_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="relative">
				{/* <div
					className="absolute text-2xl text-gray-700"
					style={{
						top: -46,
						left: 0,
						cursor: 'pointer'
					}}
					onClick={() => handlePlayersClick()}
				>
					<span className="hover:underline">Players</span>
					<i
						className="absolute ml-2 fa-solid fa-angle-right"
						style={{
							top: 4,
							left: 82,
							cursor: 'pointer'
						}}
					/>
				</div> */}
				<div>
					<ProspectProfileCard
            get_prospect_endpoint={get_prospect_endpoint}
            get_prospects_endpoint={get_prospects_endpoint}
						get_player_endpoint={get_player_endpoint}
						get_players_endpoint={get_players_endpoint}
						create_checkout_session_endpoint={create_checkout_session_endpoint}
						stripe_public_key={stripe_public_key}
						stripe_product_essential_id={stripe_product_essential_id}
						stripe_product_advanced_id={stripe_product_advanced_id}
						stripe_product_ultimate_id={stripe_product_ultimate_id}
					/>
				</div>
			</div>
		</div>
	);
}
