import React from 'react';
import TeamProfileCard from '../../Team/TeamProfileCard/TeamProfileCard';

const handleTeamsClick = () => {
	window.location.href = `/teams`;
};

export default function TeamProfilePage({
	get_team_endpoint,
	get_teams_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="relative">
				{/* <div
					className="absolute text-2xl text-gray-700"
					style={{
						top: -46,
						left: 0,
						cursor: 'pointer'
					}}
					onClick={() => handleTeamsClick()}
				>
					<span className="hover:underline">Teams</span>
					<i
						className="absolute ml-2 fa-solid fa-angle-right"
						style={{
							top: 4,
							left: 75,
							cursor: 'pointer'
						}}
					/>
				</div> */}
				<div>
					<TeamProfileCard
						get_team_endpoint={get_team_endpoint}
						get_teams_endpoint={get_teams_endpoint}
						create_checkout_session_endpoint={create_checkout_session_endpoint}
						stripe_public_key={stripe_public_key}
						stripe_product_essential_id={stripe_product_essential_id}
						stripe_product_advanced_id={stripe_product_advanced_id}
						stripe_product_ultimate_id={stripe_product_ultimate_id}
					/>
				</div>
			</div>
		</div>
	);
}
