import React from 'react';

const FilterByTeam = ({ filter, onChange }) => {
  const teams = [
    'Arizona Cardinals', 'Atlanta Falcons', 'Baltimore Ravens', 'Buffalo Bills', 'Carolina Panthers',
    'Chicago Bears', 'Cincinnati Bengals', 'Cleveland Browns', 'Dallas Cowboys', 'Denver Broncos',
    'Detroit Lions', 'Green Bay Packers', 'Houston Texans', 'Indianapolis Colts', 'Jacksonville Jaguars',
    'Kansas City Chiefs', 'Las Vegas Raiders', 'Los Angeles Chargers', 'Los Angeles Rams', 'Miami Dolphins',
    'Minnesota Vikings', 'New England Patriots', 'New Orleans Saints', 'New York Giants', 'New York Jets',
    'Philadelphia Eagles', 'Pittsburgh Steelers', 'San Francisco 49ers', 'Seattle Seahawks', 'Tampa Bay Buccaneers',
    'Tennessee Titans', 'Washington Commanders'
  ];

  const handleTeamFilterChange = (event) => {
    const { value } = event.target;
    onChange('team', value);
  };

  return (
    <div className="flex items-center my-2 lg:my-0 mr-2 md:mr-4">
      <label className="text-sm sm:text-base mr-2">Filter By Team</label>
      <select
        name="team"
        value={filter.team}
        onChange={handleTeamFilterChange}
        className="p-1.5 md:p-2 border border-gray-300 rounded-md text-xs sm:text-base"
      >
        <option value="">All</option>
        {teams.map((team) => (
          <option key={team} value={team}>{team}</option>
        ))}
      </select>
    </div>
  );
};

export default FilterByTeam;
