import React from 'react';

const ProCollegeButtons = ({
	selectedPlayingLevelType,
	handleProStatsButtonClick,
	handleCollegeStatsButtonClick,
	isProspectProfileCard
}) => {
	if (isProspectProfileCard) return null; // Hide component completely if true

	return (
		<div className="mb-4 flex flex-row">
			{/* NFL Button */}
			<div className="items-center" onClick={handleProStatsButtonClick}>
				<button
					className={`p-2 border border-gray-300 ${
						selectedPlayingLevelType === 'pro'
							? 'bg-gray-800 text-white'
							: 'bg-white text-black'
					} rounded-l-md border-r-0`}
				>
					NFL Stats
				</button>
			</div>
			{/* College Button */}
			<div className="items-center" onClick={handleCollegeStatsButtonClick}>
				<button
					className={`p-2 border border-gray-300 ${
						selectedPlayingLevelType === 'college'
							? 'bg-gray-800 text-white'
							: 'bg-white text-black'
					} rounded-r-md border-l-0`}
				>
					College Stats
				</button>
			</div>
		</div>
	);
};

export default ProCollegeButtons;
