import React, { useState } from 'react';

const RosterLessonsLearnedNotesCard = ({
	user,
	roster,
	update_lessons_learned_notes_by_roster_endpoint
}) => {
  console.log('RosterLessonsLearnedNotesCard:', user, roster, update_lessons_learned_notes_by_roster_endpoint);

	const [
		lessonsLearnedNotes,
		setLessonsLearnedNotes
	] = useState(roster.lessons_learned_notes || '');

	const handleNotesChange = async (e) => {
		const newLessonsLearnedNotes = e.target.value;
		setLessonsLearnedNotes(newLessonsLearnedNotes);

		const endpoint = update_lessons_learned_notes_by_roster_endpoint
			.replace('user_id', user.id)
			.replace('roster_id', roster.id);

		try {
			const response = await fetch(endpoint, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ lessons_learned_notes: newLessonsLearnedNotes })
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			// Optionally handle response
			// const data = await response.json();
			// console.log('Draft notes updated successfully:', data);
		} catch (error) {
			console.error('Failed to update draft notes:', error);
		}
	};

	return (
		<div>
			<div className="flex-1 bg-gray-100 rounded-lg p-4 shadow-md flex flex-col w-full lg:w-auto h-36 md:h-60">
				<h3 className="font-semibold text-lg mb-2 text-gray-700 uppercase border-b border-gray-300 pb-2">
					LESSONS LEARNED NOTES
				</h3>
				<textarea
					value={lessonsLearnedNotes}
					onChange={handleNotesChange}
					className="text-gray-700 flex-1 text-sm p-2 rounded-lg w-full h-full bg-gray-100 overflow-y-auto"
					placeholder="Click to add lessons learned notes and insights."
				/>
			</div>
		</div>
	);
};

export default RosterLessonsLearnedNotesCard;
