import React, { useState } from 'react';
import getWeek17Opponents from '../../../utils/getWeek17Oppoments';

const RosterMetricsCard = ({ roster, isDraftModeEnabled }) => {
	const players = roster.players || {};
	const quarterbacks = players.quarterbacks || [];
	const runningBacks = players.running_backs || [];
	const tightEnds = players.tight_ends || [];
	const wideReceivers = players.wide_receivers || [];

	const allPlayers = [
		...quarterbacks,
		...runningBacks,
		...tightEnds,
		...wideReceivers
	];

	const calculateAverage = (key) => {
		if (allPlayers.length === 0) return 0;
		const total = allPlayers.reduce(
			(sum, player) => sum + parseFloat(player[key] || 0),
			0
		);
		return (total / allPlayers.length).toFixed(2);
	};

	const countRookies = () => {
		return allPlayers.filter((player) => player.is_rookie).length;
	};

	const calculateAverageExperience = () => {
		if (allPlayers.length === 0) return 0;
		const currentYear = new Date().getFullYear();
		const totalExperience = allPlayers.reduce(
			(sum, player) => sum + (currentYear - player.draft_year),
			0
		);
		return (totalExperience / allPlayers.length).toFixed(1);
	};

	const calculateAverageAge = () => {
		if (allPlayers.length === 0) return 0;
		const totalAge = allPlayers.reduce(
			(sum, player) => sum + (player.age || 0),
			0
		);
		return (totalAge / allPlayers.length).toFixed(1);
	};

	const getPlayerTeamCount = () => {
		const teamCount = {};
		allPlayers.forEach((player) => {
			const teamName =
				player.team && player.team.name ? player.team.name : 'Unknown';
			if (!teamCount[teamName]) {
				teamCount[teamName] = 0;
			}
			teamCount[teamName]++;
		});
		return teamCount;
	};

	const calculateTeamStackCount = () => {
		const week17Opponents = getWeek17Opponents();
		const teamPlayerCounts = {};
		const seenMatches = new Set();

		const getLastWord = (teamName) => {
			if (!teamName) return 'Unknown';
			const words = teamName.split(' ');
			return words[words.length - 1];
		};

		// Count the number of players for each team
		for (let i = 0; i < allPlayers.length; i++) {
			const player = allPlayers[i];
			const teamName = player.team && player.team.name;

			if (teamName) {
				const displayTeam = getLastWord(teamName);
				if (!teamPlayerCounts[displayTeam]) {
					teamPlayerCounts[displayTeam] = 0;
				}
				teamPlayerCounts[displayTeam]++;
			}
		}

		// Combine counts with player counts for each matchup
		const combinedTeamCounts = {};
		for (let i = 0; i < allPlayers.length; i++) {
			const player1 = allPlayers[i];
			const team1 = player1.team && player1.team.name;

			for (let j = i + 1; j < allPlayers.length; j++) {
				const player2 = allPlayers[j];
				const team2 = player2.team && player2.team.name;

				if (team1 && team2 && week17Opponents[team1] === team2) {
					const displayTeam1 = getLastWord(team1);
					const displayTeam2 = getLastWord(team2);
					const [
						sortedTeam1,
						sortedTeam2
					] = [
						displayTeam1,
						displayTeam2
					].sort();
					const key = `${sortedTeam1}/${sortedTeam2}`;

					// Only process if the match has not been seen before
					if (!seenMatches.has(key)) {
						// Calculate total player count for the matchup
						const totalCount =
							(teamPlayerCounts[displayTeam1] || 0) +
							(teamPlayerCounts[displayTeam2] || 0);
						combinedTeamCounts[key] = totalCount;

						// Mark the match as seen
						seenMatches.add(key);
					}
				}
			}
		}

		// Filter out zero counts and return
		const result = {};
		for (const [
			key,
			count
		] of Object.entries(combinedTeamCounts)) {
			if (count > 0) {
				result[key] = count;
			}
		}

		return result;
	};

	// Calculate bye week player counts
	const calculateByeWeekPlayerCount = () => {
		const byeWeeks = {};
		allPlayers.forEach((player) => {
			const byeWeek = player.bye_week;
			if (byeWeek) {
				if (!byeWeeks[byeWeek]) {
					byeWeeks[byeWeek] = 0;
				}
				byeWeeks[byeWeek]++;
			}
		});
		return byeWeeks;
	};

	// Calculate injury statuses
	const calculateInjuryStatuses = () => {
		const injuryStatuses = {};
		allPlayers.forEach((player) => {
			// console.log(player); // Check the structure of each player object
			const status = player.status; // Access the status field
			if (status) {
				if (!injuryStatuses[status]) {
					injuryStatuses[status] = 0;
				}
				injuryStatuses[status]++;
			}
		});
		return injuryStatuses;
	};

	// Toggle Roster Metrics visibility
	const [
		isMetricsVisible,
		setIsMetricsVisible
	] = useState(true);

	const toggleMetricsVisibility = () => {
		setIsMetricsVisible(!isMetricsVisible);
	};

	const avgFantasyFitnessScore = calculateAverage('fantasy_fitness_grade');
	const avgFantasyFitnessTalentScore = calculateAverage('talent_grade');
	const avgFantasyFitnessTeamScore = calculateAverage('team_grade');
	const numberOfRookies = countRookies();
	const avgPlayerExperience = calculateAverageExperience();
	const avgPlayerAge = calculateAverageAge();
	const playerTeamCount = getPlayerTeamCount();
	const teamStackCount = calculateTeamStackCount();
	const byeWeekPlayerCount = calculateByeWeekPlayerCount();
	const injuryStatuses = calculateInjuryStatuses();

	return (
		(
			<div className="flex flex-col justify-between">
				<div className="flex flex-row items-center">
					<h2 className="text-2xl font-bold text-gray-800">Roster Metrics</h2>
					<i
						className={`fas fa-chevron-${isMetricsVisible
							? 'up'
							: 'down'} text-gray-500 cursor-pointer ml-2`}
						onClick={toggleMetricsVisibility}
					/>
				</div>

				{isMetricsVisible && (
					<div
						className={`mt-4 grid ${isDraftModeEnabled
							? 'grid-cols-1 w-44 xl:w-56 h-96'
							: 'sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6'} gap-4`}
						// style={isDraftModeEnabled ? { width: 230 } : {}}
					>
						<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
							<label className="block text-xs uppercase font-medium text-gray-700">
								Position Player Count
							</label>
							<div className="grid grid-cols-2 mb-2">
								<div>
									<p>QB: {quarterbacks.length}</p>
									<p>RB: {runningBacks.length}</p>
								</div>
								<div>
									<p>WR: {wideReceivers.length}</p>
									<p>TE: {tightEnds.length}</p>
								</div>
							</div>
							<div className="mb-2">
								<label className="block text-xs uppercase font-medium text-gray-700">
									Rookie Count
								</label>
								<p>{numberOfRookies}</p>
							</div>
							<div className="mb-2">
								<label className="block text-xs uppercase font-medium text-gray-700">
									Average Player Age
								</label>
								<p>{avgPlayerAge} yrs</p>
							</div>
							<div>
								<label className="block text-xs uppercase font-medium text-gray-700">
									Average Player Experience
								</label>
								<p>{avgPlayerExperience} yrs</p>
							</div>
						</div>

						<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
							<div className="mb-2">
								<label className="block text-xs uppercase font-medium text-gray-700">
									Average Fantasy Fitness Score
								</label>
								<p>{avgFantasyFitnessScore}</p>
							</div>
							<div className="mb-2">
								<label className="block text-xs uppercase font-medium text-gray-700">
									Average Fantasy Fitness Talent Score
								</label>
								<p>{avgFantasyFitnessTalentScore}</p>
							</div>
							<div>
								<label className="block text-xs uppercase font-medium text-gray-700">
									Average Fantasy Fitness Team Score
								</label>
								<p>{avgFantasyFitnessTeamScore}</p>
							</div>
						</div>

						<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300 h-32 sm:h-44 md:h-60 overflow-y-auto">
							<label className="block text-xs uppercase font-medium text-gray-700">
								Team Player Count
							</label>
							<div>
								{Object.keys(playerTeamCount).length === 0 ? (
									<p>0</p>
								) : (
									Object.keys(playerTeamCount).map((team) => (
										<p key={team}>
											{team}: {playerTeamCount[team]}
										</p>
									))
								)}
							</div>
						</div>

						<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300">
							<label className="block text-xs uppercase font-medium text-gray-700">
								Wk17 Team Stack Player Count
							</label>
							<div>
								{Object.keys(teamStackCount).length === 0 ? (
									<p>No week 17 stacks data.</p>
								) : (
									Object.keys(teamStackCount).map((matchup) => (
										<p key={matchup}>
											{matchup}: {teamStackCount[matchup]}
										</p>
									))
								)}
							</div>
						</div>

						{/* Bye Week Player Count Card */}
						<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300 h-32 sm:h-44 md:h-60 overflow-y-auto">
							<label className="block text-xs uppercase font-medium text-gray-700">
								Bye Week Player Count
							</label>
							<div>
								{Object.keys(byeWeekPlayerCount).length === 0 ? (
									<p>No bye week data.</p>
								) : (
									Object.keys(byeWeekPlayerCount).map((byeWeek) => (
										<p key={byeWeek}>
											Week {byeWeek}: {byeWeekPlayerCount[byeWeek]}
										</p>
									))
								)}
							</div>
						</div>
						{/* Injury Status Card */}
						<div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300 h-32 sm:h-44 md:h-60 overflow-y-auto">
							<label className="block text-xs uppercase font-medium text-gray-700">
								Player Injury Statuses
							</label>
							<div>
								{Object.keys(injuryStatuses).filter(
									(status) => injuryStatuses[status] > 0
								).length === 0 ? (
									<p>No injury status data.</p>
								) : (
									Object.keys(injuryStatuses)
										.filter((status) => injuryStatuses[status] > 0)
										.map((status) => (
											<p key={status}>
												{status}: {injuryStatuses[status]}
											</p>
										))
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		)
	);
};

export default RosterMetricsCard;
