import React from 'react';

const StatsTypeButtons = ({
	statsType1,
	statsType2,
	selectedStatType,
	handleStatsType1ButtonClick,
	handleStatsType2ButtonClick
}) => {
	return (
		<div className="mb-4 flex flex-row">
			{/* Button for statsType1 */}
			<div className="items-center" onClick={handleStatsType1ButtonClick}>
				<button
					className={`p-2 border border-gray-300 ${
						selectedStatType === statsType1.toLowerCase()
							? 'bg-gray-800 text-white'
							: 'bg-white text-black'
					} rounded-l-md border-r-0`}
				>
					{statsType1}
				</button>
			</div>
			{/* Button for statsType2 */}
			<div className="items-center" onClick={handleStatsType2ButtonClick}>
				<button
					className={`p-2 border border-gray-300 ${
						selectedStatType === statsType2.toLowerCase()
							? 'bg-gray-800 text-white'
							: 'bg-white text-black'
					} rounded-r-md border-l-0`}
				>
					{statsType2}
				</button>
			</div>
		</div>
	);
};

export default StatsTypeButtons;
