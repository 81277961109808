import React, { useState, useEffect, useRef } from 'react';
import FilterBySearchInput from '../../Filters/FilterBySearchInput';
import TeamSearchBarResultsList from '../TeamSearchBar/TeamSearchBarResultsList';

const TeamSearchBar = ({
	get_teams_endpoint,
	handleTeamSelect,
	isVisibleByDefault,
	isCompareModal,
	isDarkMode
}) => {
	const [
		search,
		setSearch
	] = useState('');
	const [
		teams,
		setTeams
	] = useState([]);
	const [
		isSearchInputVisible,
		setIsSearchInputVisible
	] = useState(isVisibleByDefault || false);
	const [
		isSearchBarResultsList,
		setIsSearchBarResultsList
	] = useState(true);
	const searchInputRef = useRef(null);
	const searchBarContainerRef = useRef(null);

	const fetchTeams = () => {
		let url = `${get_teams_endpoint}?search=${search}`;
		fetch(url)
			.then((response) => response.json())
			.then((result) => {
				setTeams(result.data || []);
			})
			.catch((error) => {
				console.error('Error fetching teams:', error);
			});
	};

	useEffect(
		() => {
			setTeams([]);
			if (search) {
				fetchTeams();
			}
		},
		[
			search,
			get_teams_endpoint
		]
	);

	const handleSearchInputChange = (event) => {
		setIsSearchBarResultsList(true);
		setSearch(event.target.value);
	};

	const handleTeamClick = (team) => {
		if (isCompareModal === true) {
			handleTeamSelect(team);
			setSearch('');
			setIsSearchBarResultsList(false);
		} else {
			window.location.href = `/teams/${team.id}`;
		}
		setIsSearchBarResultsList(false);
		setSearch('');

		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				searchBarContainerRef.current &&
				!searchBarContainerRef.current.contains(event.target)
			) {
				setIsSearchBarResultsList(false);
				setSearch('');
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="flex items-center relative" ref={searchBarContainerRef}>
			{/* Magnifying glass icon */}
			<i
				className={`fa-solid fa-magnifying-glass cursor-pointer ${isDarkMode
					? 'text-gray-500'
					: 'text-white'} text-2xl py-5 pr-2.5`}
				onClick={() => setIsSearchInputVisible(!isSearchInputVisible)}
			/>

			{/* Search input and results */}
			<div
				className={`ml-2 relative ${isSearchInputVisible
					? 'contents'
					: 'hidden'}`}
			>
				<FilterBySearchInput
					ref={searchInputRef}
					value={search}
					placeholder="Search for a team"
					onChange={handleSearchInputChange}
					onEnter={fetchTeams}
					isDarkMode={isDarkMode}
				/>
				{teams.length > 0 &&
				isSearchBarResultsList && (
					<TeamSearchBarResultsList
						teams={teams}
						handleTeamClick={handleTeamClick}
					/>
				)}
			</div>
		</div>
	);
};

export default TeamSearchBar;
