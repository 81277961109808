import React, { useState, useEffect } from 'react';
import { useCsrfToken } from '../../Hooks/useCsrfToken';
import UserAssessmentsTable from '../../UserAssessments/UserAssessmentsTable/UserAssessmentsTable';

function CourseContentDisplay({ selectedContent, course_id }) {
	const csrfToken = useCsrfToken();
	const [
		assessmentId,
		setAssessmentId
	] = useState(null);
	const [
		assessmentData,
		setAssessmentData
	] = useState([]);
	const [
		userAssessments,
		setUserAssessments
	] = useState([]);

	const [
		selectedAnswers,
		setSelectedAnswers
	] = useState({});
	const [
		score,
		setScore
	] = useState(null);
	const [
		assessmentStarted,
		setAssessmentStarted
	] = useState(false);

	// Fetch user assessments on page load and on refresh
	const getUserAssessments = async () => {
		try {
			const response = await fetch(
				`/api/v1/user_assessments?content_item_id=${selectedContent.id}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRF-Token': csrfToken
					}
				}
			);

			if (response.ok) {
				const data = await response.json();
				setUserAssessments(data);
			} else {
				console.error('Failed to fetch user assessments');
			}
		} catch (error) {
			console.error('Error fetching user assessments:', error);
		}
	};

	// Call the getUserAssessments function when selectedContent changes
	useEffect(
		() => {
			if (selectedContent) {
				getUserAssessments();
			}
		},
		[
			csrfToken,
			selectedContent
		]
	);

	// Reset assessment state when selectedContent changes
	useEffect(
		() => {
			setAssessmentId(null);
			setAssessmentData([]);
			setScore(null);
			setSelectedAnswers({});
			setAssessmentStarted(false);
		},
		[
			selectedContent
		]
	);

	if (!selectedContent) return null;

	const handleStartAssessment = async () => {
		try {
			const response = await fetch(`/api/v1/user_assessments/new`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
				},
				body: JSON.stringify({
					course_id: course_id,
					content_item_id: selectedContent.id
				})
			});

			if (!response.ok) {
				throw new Error('Failed to create user assessment');
			}

			const data = await response.json();
			setAssessmentId(data.assessment_id);
			setAssessmentData(data.questions);
			setAssessmentStarted(true);
		} catch (error) {
			console.error('Error starting assessment:', error);
		}
	};

	const handleSubmit = async () => {
		const unansweredQuestions = assessmentData.filter(
			(question) => !selectedAnswers[question.question_id]
		);

		if (unansweredQuestions.length > 0) {
			const questionList = unansweredQuestions
				.map((q) => q.question_text)
				.join('\n\n');
			alert(
				`Please answer the following questions before submitting:\n\n${questionList}`
			);
			return;
		}

		const responses = Object.entries(
			selectedAnswers
		).map(([ questionId, answerId
		]) => ({
			question_id: parseInt(questionId),
			answer_id: parseInt(answerId)
		}));

		try {
			const response = await fetch(
				`/api/v1/user_assessments/${assessmentId}/responses`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRF-Token': csrfToken
					},
					body: JSON.stringify({ responses })
				}
			);

			if (!response.ok) {
				throw new Error('Failed to submit responses');
			}

			const result = await response.json();
			console.log(result.message);

			const totalQuestions = assessmentData.length;
			let correctAnswers = 0;

			assessmentData.forEach((question) => {
				const userAnswerId = selectedAnswers[question.question_id];
				const isCorrect = question.answers.some(
					(answer) =>
						answer.answer_id === parseInt(userAnswerId) && answer.correct
				);

				if (isCorrect) {
					correctAnswers++;
				}
			});

			const percentageScore = (correctAnswers / totalQuestions * 100).toFixed(
				0
			);
			setScore(percentageScore);
		} catch (error) {
			console.error('Error submitting responses:', error);
		}
	};

	// Function to refresh the content, reset state, and fetch new user assessments
	const handleRefresh = () => {
		setAssessmentId(null);
		setAssessmentData([]);
		setScore(null);
		setSelectedAnswers({});
		setAssessmentStarted(false);

		// Trigger a new call to the getUserAssessments API
		getUserAssessments();
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	};

	switch (selectedContent.content_type) {
		case 'Video':
			return (
				<React.Fragment>
					<iframe
						style={{ width: '100%', minHeight: 'calc(100vh - 20rem)' }}
						src={
							selectedContent.url ||
							'https://www.youtube.com/embed/Hg9bDlpKjnw?si=GLaWED6_t6_YIYg0'
						}
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
					/>
				</React.Fragment>
			);
		case 'Quiz':
		case 'Final Exam':
			return (
				<React.Fragment>
					<div className="bg-white shadow-inner py-6 px-12">
						<h2 className="text-2xl font-semibold text-gray-800 mb-4">
							{selectedContent.title}
						</h2>
						{userAssessments.length > 0 &&
						!assessmentStarted && (
							<div className="mb-6">
								<h3 className="text-lg font-semibold mb-2">
									Previous Attempts
								</h3>
								<UserAssessmentsTable userAssessments={userAssessments} />
							</div>
						)}

						{!assessmentStarted && (
							<React.Fragment>
								<p className="text-gray-700 my-10">
									{selectedContent.description}
								</p>
								<div className="flex justify-center">
									<button
										className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
										onClick={handleStartAssessment}
									>
										Start {selectedContent.content_type}
									</button>
								</div>
							</React.Fragment>
						)}

						{score !== null && (
							<h3 className="text-xl font-semibold">Your Score: {score}%</h3>
						)}

						{assessmentData && (
							<React.Fragment>
								<div className="mt-6">
									{assessmentData.map((question) => {
										const userAnswerId = selectedAnswers[question.question_id]; // User's selected answer ID
										const isCorrect = question.answers.some(
											(answer) =>
												answer.answer_id === parseInt(userAnswerId) &&
												answer.correct
										); // Determine if the user's answer was correct

										return (
											<div key={question.question_id} className="mb-4">
												<p className="text-gray-800 font-medium">
													{question.question_text}
													{/* Only show indicators after the assessment is submitted */}
													{score !== null &&
													userAnswerId && (
														<span className="ml-2">
															{isCorrect ? (
																<span className="text-green-500">
																	✓ Correct
																</span>
															) : (
																<span className="text-red-500">
																	✗ Incorrect
																</span>
															)}
														</span>
													)}
												</p>
												<div className="ml-4">
													{question.answers.map((answer) => (
														<div
															key={answer.answer_id}
															className="flex items-center"
														>
															<input
																type="radio"
																id={`answer-${answer.answer_id}`}
																name={`question-${question.question_id}`}
																value={answer.answer_id}
																disabled={score !== null} // Disable input after submission
																onChange={(e) => {
																	const answerId = e.target.value;
																	setSelectedAnswers((prev) => ({
																		...prev,
																		[question.question_id]: answerId
																	}));
																}}
															/>
															<label
																htmlFor={`answer-${answer.answer_id}`}
																className="ml-2 text-gray-700"
															>
																{answer.answer_text}
															</label>
														</div>
													))}
												</div>

												{/* Show correct answer and explanation if the answer is incorrect */}
												{score !== null &&
												!isCorrect && (
													<div className="mt-2 text-red-600">
														<p>
															Correct Answer:{' '}
															{
																question.answers.find(
																	(answer) => answer.correct
																).answer_text
															}
														</p>
														<p>
															Explanation:{' '}
															{
																question.answers.find(
																	(answer) => answer.correct
																).explanation
															}
														</p>
													</div>
												)}
											</div>
										);
									})}
								</div>
								{/* Show submit button only if assessment has started */}
								{assessmentStarted &&
								score === null && (
									<div className="flex justify-center mt-4">
										<button
											className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
											onClick={handleSubmit}
										>
											Submit Answers
										</button>
									</div>
								)}
								{score !== null && (
									<div className="flex justify-center">
										<button
											className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
											onClick={handleRefresh}
										>
											See history
										</button>
									</div>
								)}
							</React.Fragment>
						)}
					</div>
				</React.Fragment>
			);
		default:
			return (
				<React.Fragment>
					<p>Content type not yet supported.</p>
				</React.Fragment>
			);
	}
}

export default CourseContentDisplay;
