import React, { useState, Fragment } from 'react';
import CircularBarChart from '../../../Charts/CircularBarChart';

const CompareTeamEnvironmentScores = ({ selectedTeams }) => {
	const maxPossibleScore = 10;

	// States for each tooltip
	const [
		isOLineHovered,
		setIsOLineHovered
	] = useState(false);
	const [
		isQBWRTERBHovered,
		setIsQBWRTERBHovered
	] = useState(false);
	const [
		isCoachingHovered,
		setIsCoachingHovered
	] = useState(false);

	const tooltipStyles = {
		position: 'absolute',
		top: '100%',
		left: '125px',
		transform: 'translateX(-50%)',
		whiteSpace: 'wrap',
		width: '250px'
	};

	const tooltipContent = {
		oline:
			'This score is calculated based on the quality of the offensive line, which impacts players ability to produce fantasy points.',
		qbwrterb:
			'This score is calculated based on the quality of the skill position players on the team, which impacts players ability to produce fantasy points.',
		coaching:
			'This score is calculated based on the quality of the head coach and offensive coordinator, which impacts players ability to produce fantasy points.'
	};

	const renderTooltip = (isHovered, content) =>
		isHovered && (
			<div
				className="p-2 bg-white border border-gray-300 shadow-lg rounded-lg text-sm z-10"
				style={tooltipStyles}
			>
				{content}
			</div>
		);

	return (
		<Fragment>
			{/* O-Line */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					O-Line
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isOLineHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsOLineHovered(true)}
							onMouseLeave={() => setIsOLineHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isOLineHovered, tooltipContent.oline)}
					</div>
				</td>
				{selectedTeams.map((team, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="O-Line"
								chartSize="xs"
								value={
									team && team.offensive_line_grade ? (
										team.offensive_line_grade
									) : (
										0
									)
								}
								maxPossible={maxPossibleScore}
								infoIconText={tooltipContent.oline}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* QB/WR/TE/RB */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					QB/WR/TE/RB
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isQBWRTERBHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsQBWRTERBHovered(true)}
							onMouseLeave={() => setIsQBWRTERBHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isQBWRTERBHovered, tooltipContent.qbwrterb)}
					</div>
				</td>
				{selectedTeams.map((team, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="WR/TE/RB"
								chartSize="xs"
								value={(((team && team.wide_receivers_grade) || 7.5) * 0.5 +
									((team && team.tight_ends_grade) || 7.5) * 0.25 +
									((team && team.running_backs_grade) || 7.5) * 0.25).toFixed(
									2
								)}
								maxPossible={maxPossibleScore}
								infoIconText={tooltipContent.qbwrterb}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* Coaching */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Coaching
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isCoachingHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsCoachingHovered(true)}
							onMouseLeave={() => setIsCoachingHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isCoachingHovered, tooltipContent.coaching)}
					</div>
				</td>
				{selectedTeams.map((team, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="Coaching"
								chartSize="xs"
								value={team && team.head_coach_grade}
								maxPossible={maxPossibleScore}
								infoIconText={tooltipContent.coaching}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
		</Fragment>
	);
};

export default CompareTeamEnvironmentScores;
